<template>
    <div class="p-2 d-flex flex-column flex-fill">

        <div class="mb-2" v-if="schedule && pet">
            <div class="">
                <router-link to="/training" class="h4 mb-0 text-white d-flex align-items-center" style="">
                    <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                    Back
                </router-link>
            </div>

            <h1 class="font-weight-bolder text-white text-center mt-5">Thank You!</h1>
            <div class="h3 font-weight-bold mt-1 text-white text-center">
                Your booking has been confirm.
            </div>

            <!-- <div class="h3 font-weight-bold mt-5 text-white text-center">
                Here Are Your Receipt
            </div> -->

            <div class="d-flex mt-2 mb-1 align-items-center justify-content-between" style="gap:10px">
                <div class="h3 font-weight-bolder text-white mb-0">
                    {{pet.pet_name}}
                </div>
                <!-- <div class="h3 font-weight-bolder text-white mb-0 d-flex align-items-center">
                    <i class="bx bx-dollar" style="font-size: 20px;" ></i> {{ training.tr_price }}
                </div> -->
            </div> 

            <div>
                <div class="h5 text-white">
                    {{ moment(schedule.ws_date).format('dddd, DD MMMM YYYY') }}, {{ schedule.ws_time }}
                </div>
                <!-- <p class="text-white">
                    {{ training.tr_details }}
                </p> -->
            </div>

            <div class="d-flex mt-3 mb-1 align-items-center justify-content-between" style="gap:10px">
                <div class="h3 font-weight-bolder text-white mb-0">
                    Note for Walker
                </div> 
            </div>
            <h5 class="text-white font-weight-bold h4 mt-2">Note for Walker</h5>
            <div class="mt-1" v-for="item in note">
                <div class="text-white" style="gap:10px">
                    {{ moment(item.wn_date).format('dddd, DD MMMM YYYY') }}, {{ item.wn_time }}
                </div>
                <p class="text-white">
                    {{ item.wn_text }}
                </p>
            </div>

        </div>

        <div class="mt-auto" v-if="pet">
            <hr style="border-color: #FFFFFF;" />
            <div class="d-flex mt-2 mb-1 align-items-center justify-content-between" style="gap:10px">
                <div class="h3 font-weight-bolder text-white mb-0">
                    Pet Walker
                </div>
                <!-- <div class="h3 font-weight-bolder text-white mb-0 d-flex align-items-center">
                    <i class="bx bx-dollar" style="font-size: 20px;" ></i> {{ training.tr_price }}
                </div> -->
            </div>

            <div class="" style="margin-top: .5em;">
                <div class="d-flex align-items-center justify-content-between" style="gap:10px"> 
                    <div>
                        <router-link to="/activities" class="text-white d-flex align-items-center"
                            style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 10px;">
                            <div class="h4 text-white mb-0 ml-auto">Back To Home</div>
                        </router-link>
                    </div>
                    <!-- <div>
                        <div class="text-white d-flex align-items-center"
                            style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 10px;">
                            <div class="h4 text-white mb-0 ml-auto">Print Receipt</div>
                        </div>
                    </div> -->
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import store from '@/store'
import moment from 'moment'

export default {
    computed: {
        schedule() {
            return this.invoice?.schedule;
        },
        training() {
            return this.schedule?.training;
        },
        pet() {
            return this.invoice?.walker.pet;
        },
        note(){
            return this.invoice?.walker.note
        }, 
    },
    async mounted() {
        this.invoice = await store.dispatch("walker/GetInvoice", this.id);
    },
    data() {
        return {
            moment,
            invoice: null,
            id: this.$route.params.id
        };
    },
}
</script>

<style>
input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
</style>